import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";

export const frontmatter = {
  title: "Día -02",
  week: "Semana 0",
  day: "11",
  month: "Mar",
  monthNumber: "03",
  date: "2020-03-11",
  path: "/cronologia/semana-0#dia-11-mar",
};
const Day11M = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDataEu fecha={frontmatter.date} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong> hasta el momento se han registrado 2.128
          casos (506 más que el día anterior) y 47 fallecidos (2,2% de los casos
          positivos). El total de personas en UCI asciende a 142.
        </ModText>
        <ModText>
          Las comunidades autónomas con una mayor incidencia acumulada en los
          últimos días son Madrid (1.024 casos y 31 fallecidos), País Vasco (225
          casos y 6 fallecidos), La Rioja (179 casos y 2 fallecidos), Cataluña
          (156 casos y 3 fallecidos) y Andalucía (90 casos). Las Ciudades
          Autónomas de Ceuta y Melilla se mantienen libres de contagio por
          coronavirus.
        </ModText>
        <ModText>
          La Comunidad de Madrid y La Rioja suspenden la actividad lectiva en
          todos sus centros educativos durante al menos dos semanas para
          contener la propagación del coronavirus.
        </ModText>
        <ModText>
          El <strong>Ministerio de Industria, Comercio y Turismo</strong> ha
          elaborado una{" "}
          <InlineLink link="https://www.lamoncloa.gob.es/serviciosdeprensa/notasprensa/industria/Documents/2020/110320-Gu%C3%ADaBuenasPr%C3%A1cticasCOVID-19.pdf">
            guía de buenas prácticas sanitarias
          </InlineLink>{" "}
          para los establecimientos del sector turístico y sus trabajadores.
        </ModText>
        <ModImage
          src="/images/svg/11_mar_3_img_juntas.svg"
          alt="medidas de higiene personal"
        />
        <ModTwoCols
          src="/images/svg/img-oms.svg"
          alt="Casos confirmados en Europa"
          small={true}
          inverted={false}
        >
          La Organización Mundial de la Salud (OMS) declara que el nuevo brote
          de coronavirus es una pandemia mundial, lo que subraya la velocidad y
          tenacidad con la que se ha propagado.
        </ModTwoCols>

        <ModReferenceList title="Guías y documentos publicados">
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/serviciosdeprensa/notasprensa/industria/Documents/2020/110320-Gu%C3%ADaBuenasPr%C3%A1cticasCOVID-19.pdf"
            name="Guía de buenas prácticas sanitarias"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day11M;
